import React from 'react'
import datasn from '../../course.json';
import BatchTime from '../BatchTime';
const Riskvidform = () => {
  return (
    <>
    <div className="col-lg-4">
              <div className="course-sidebar sticky-top rbt-shadow-box course-sidebar-top rbt-gradient-border">
                <div className="inner">
                  {/* <!-- Start Viedo Wrapper  --> */}
                 
                    <div className="video-content">
                      <img
                        className="w-100 rbt-radius"
                        src="assets/images/about/video-01.jpg"
                        alt="Video Images"
                      />
                      <div className="position-to-top">
                        <span className="rbt-btn rounded-player-2 with-animation">
                          <span className="play-icon"></span>
                        </span>
                      </div>
                     
                    </div>
                  
                  {/* <!-- End Viedo Wrapper  --> */}

                  <div className="content-item-content">
                    <div className="rbt-price-wrapper d-flex flex-wrap align-items-center justify-content-between">
                      <div className="rbt-price">
                        <span className="current-price">₹29,997/-</span>
                        <span className="off-price">₹49,997/-</span>
                      </div>
                     
                    </div>

                    <div className="add-to-card-button mt--15">
                      <a
                        className="rbt-btn btn-gradient icon-hover w-100 d-block text-center"
                        href="#"
                      >
                        <span className="btn-text">Buy Now</span>
                        <span className="btn-icon">
                          <i className="feather-arrow-right"></i>
                        </span>
                      </a>
                    </div>

                    {/* <span className="subtitle">
                      <i className="feather-rotate-ccw"></i> 30-Day Money-Back
                      Guarantee
                    </span> */}

                    <div className="rbt-widget-details has-show-more">
                      <ul className="has-show-more-inner-content rbt-course-details-list-wrapper">
                        <li>
                          <span>Duration</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            6 Months
                          </span>
                        </li>
                        {/* <li>
                          <span>Efforts</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            3-6 Hours per week
                          </span>
                        </li> */}
                        <li>
                          <span>Next Batch Start On</span>
                          <span className="rbt-feature-value rbt-badge-5">
                          <BatchTime />
                          </span>
                        </li>
                        <li>
                          <span>Learning Mode</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            Online Interactive Learning
                          </span>
                        </li>
                        <li>
                          <span>Enrolled</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            105
                          </span>
                        </li>
                        {/* <li>
                          <span>Lectures</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            50
                          </span>
                        </li> */}
                        <li>
                          <span>Skill Level</span>
                          <span className="rbt-feature-value rbt-badge-5">
                          Beginner to Advanced
                          </span>
                        </li>
                        <li>
                          <span>Language</span>
                          <span className="rbt-feature-value rbt-badge-5">
                            English
                          </span>
                        </li>
                      </ul>
                      <div className="rbt-show-more-btn">Show More</div>
                    </div>

                    <div className="social-share-wrapper mt--30 text-center">
                      <div className="rbt-post-share d-flex align-items-center justify-content-center">
                        <ul className="social-icon social-default transparent-with-border justify-content-center">
                          <li>
                            <a href="https://www.facebook.com/">
                              <i className="feather-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.twitter.com">
                              <i className="feather-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/">
                              <i className="feather-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkdin.com/">
                              <i className="feather-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <hr className="mt--20" />
                      <div className="contact-with-us text-center">
                        <p>For details about the course</p>
                        <p className="rbt-badge-2 mt--10 justify-content-center w-100">
                          <i className="feather-phone mr--5"></i> Call Us:{" "}
                          <a href="tel:+919311107230">
                            <strong>+91 9311107230</strong>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      <div class="rbt-separator-mid">
        <div class="container">
          <hr class="rbt-separator m-0" />
        </div>
      </div>
    </>
  )
}

export default Riskvidform